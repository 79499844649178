<template>
	<div class="mainBox">
		<template v-if="bannerList.length > 0">
		<div class="bannerBox">
			<swiper
			  :spaceBetween="30" 
			  :centeredSlides="true" 
			  :autoplay='{ "delay": 2500, "disableOnInteraction": false }' 
			  :pagination='{ "clickable": true }' 
			  :navigation="false" 
				:style="{
				  '--swiper-navigation-color': '#000',
				  '--swiper-pagination-color': '#000'
				}"
			  class="mySwiper"
			  >
			  <swiper-slide v-for="(item,index) in bannerList" :key="index">
					<div class="banner cimg">
						<img :src="item">
					</div>
				</swiper-slide>
			</swiper>
		</div>
		<div class="conBox">
			<template v-if="newsList.length > 0">
				<ul class="newsList">
					<li v-for="(item,index) in newsList" :key="index" @click="handleDetail(item)">
						<div class="iconBox cimg">
							<img v-if="item.icon" :src="item.icon">
							<span v-else>{{item.gameName}}</span>
						</div>
						<div class="titleBox">
							<p class="gameName">{{item.gameName}}</p>
							<p class="title van-multi-ellipsis--l2">{{item.title}}</p>
							<p>{{item.source}}<span class="dicon"><img src="~@/assets/images/common/google_icon.png"></span></p>
						</div>
					</li>
				</ul>
			</template>
			<p class="text-center" v-else>
			  {{ $t('common.noMore') }}
			</p>
		</div>
		</template>
		<template v-else>
			<div class="emptyBox">
				<van-loading color="#51bac0" />
			</div>
		</template>
	</div>
</template>

<script setup>
	import { Swiper, SwiperSlide } from 'swiper/vue';
	
	import SwiperCore, { Autoplay,Pagination,Navigation } from 'swiper/core';
	SwiperCore.use([Autoplay,Pagination,Navigation]);
	
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import { getNewsList } from "@/api/index";
	
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const bannerList = ref([])
	const newsList = ref([])
	
	onMounted(() => {
		getNewsList().then((res) => {
			if(res.msg){
				bannerList.value.push(res.msg)
			}
			newsList.value = res.data || []
		})
	})
	const handleDetail = (row) => {
		router.push({
		  path:'/news/'+row.id
		});
	}
	const goGameDetail = (row) => {
		router.push({
		  path:'/game/'+row.gameId
		});
	}
</script>

<style lang="less" scoped>
	.mainBox{
		width: 100%;height: calc(100% - 6rem);;
		overflow-y: scroll;
	}
	.conBox{
		width: 100%;padding: 0.75rem;box-sizing: border-box;
		ul.newsList{
			li{
				width: 100%;margin-bottom: 0.625rem;padding: 0.5rem 0.75rem;box-sizing: border-box;
				box-shadow: 0 0.1875rem 0.625rem 0px #eFeFeF;border-radius: 0.5rem;background: #fff;
				display: flex;align-items: center;
				.iconBox{
					width: 7rem;height: 4.25rem;margin-right: 0.625rem;flex-shrink: 0;
					border-radius: 0.3125rem;overflow: hidden;
					span{
						display: inline-flex;width: 100%;height: 100%;background: #eee;color: #ccc;
						align-items: center;justify-content: center;text-align: center;
					}
				}
				.titleBox{
					flex: 1;
				}
				p{
					font-size: 0.75rem;color: #90939A;
					span{
						display: inline-block;
						float: right;
						&.dicon{
							display: inline-block;width: 1.125rem;height: 1.125rem;margin-left: 0.5rem;
							img{
								width: 100%;
							}
						}
					}
					&.gameName{
						font-size: 0.875rem;color: #51B8BF;
					}
					&.title{
						font-size: 1rem;font-weight: 500;color: #14181B;margin-bottom: 0.3125rem;
					}
				}
			}
		}
	}
</style>